var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-top":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg10":""}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"color":"white","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.credit_notes,"items-per-page":10,"item-key":"id","mobile-breakpoint":"0","sort-by":"name","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Facturas")]),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Fecha","hide-details":"auto","required":"","dense":"","outlined":"","type":"date"},on:{"input":_vm.get_notes},model:{value:(_vm.issueDate),callback:function ($$v) {_vm.issueDate=$$v},expression:"issueDate"}})],1)]},proxy:true},{key:"item.cufe",fn:function(ref){
var item = ref.item;
return [(item.cufe)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-all ")]):_vm._e()]}},{key:"item.dian_id",fn:function(ref){
var item = ref.item;
return [(item.dian_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-all ")]):_vm._e()]}},{key:"item.cufe_n",fn:function(ref){
var item = ref.item;
return [(item.cufe_n)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-all ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.get_e_doc(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)})],1)],1)],1),_c('noteViewer',{attrs:{"item":_vm.item,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }